@use "sass:string";
@import './fonts';
@import './font-family';

// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import 'bootstrap/scss/functions';

// 2. Include any default variable overrides here
@import './_variables';

// 3. Include remainder of required Bootstrap stylesheets
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/maps';

// 4. Include any optional Bootstrap components as you like
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/grid';

@import 'bootstrap/scss/functions';

// Optional Bootstrap components here
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/utilities';

// etc
@import './_pagination';

// global utilities
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

// width/height by brake points
$dimensions: (25, 33, 40, 50, 60, 75, 100);

// colors for generating custom helpers
$theme-colors-plus: map-merge($theme-colors, (
  'white': $white,
  'black': $black,
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'gray-500': $gray-500,
  'gray-600': $gray-600,
  'gray-700': $gray-700,
  'gray-800': $gray-800,
  'gray-900': $gray-900,
  'green': $success-500,
  'success-100': $success-100,
  'success-200': $success-200,
  'success-600': $success-600,
  'primary-100': $primary-100,
  'primary-200': $primary-200,
  'primary-600': $primary-600,
  'primary-700': $primary-700,
  'warning-100': $warning-100,
  'warning-200': $warning-200,
  'warning-300': $warning-300,
  'warning-500': $warning-500,
  'warning-600': $warning-600,
  'danger-600': $danger-600,
  'graphite-light-grey-t': $graphite-light-grey-t,
));

#app {
  .fw-bold {
    font-family: "visuelt-bold-pro", sans-serif;
  }

  .fw-normal {
    font-family: "visuelt-pro", sans-serif;
    font-weight: 400;
  }

  .font-secondary {
    font-family: $font-family-secondary;
  }

  // custom spacing
  .pt-0125r {
    padding-top: 0.125rem;
  }
  .pb-0125r {
    padding-bottom: 0.125rem;
  }
  .py-0125r {
    @extend .pt-0125r;
    @extend .pb-0125r;
  }
  .pl-0125r {
    padding-left: 0.125rem;
  }
  .pr-0125r {
    padding-right: 0.125rem;
  }
  .px-0125r {
    @extend .pl-0125r;
    @extend .pr-0125r;
  }
  .p-0125r {
    @extend .px-0125r;
    @extend .py-0125r;
  }

  .pt-01875r {
    padding-top: 0.1875rem;
  }
  .pb-01875r {
    padding-bottom: 0.1875rem;
  }
  .py-01875r {
    @extend .pt-01875r;
    @extend .pb-01875r;
  }
  .pl-01875r {
    padding-left: 0.1875rem;
  }
  .pr-01875r {
    padding-right: 0.1875rem;
  }
  .px-01875r {
    @extend .pl-01875r;
    @extend .pr-01875r;
  }
  .p-01875r {
    @extend .px-01875r;
    @extend .py-01875r;
  }

  .pt-075r {
    padding-top: 0.75rem;;
  }

  .pb-075r {
    padding-bottom: 0.75rem;;
  }

  .py-075r {
    @extend .pt-075r;
    @extend .pb-075r;
  }

  .ps-075r {
    padding-left: 0.75rem;;
  }

  .pe-075r {
    padding-right: 0.75rem;;
  }

  .px-075r {
    @extend .ps-075r;
    @extend .pe-075r;
  }

  .p-075r {
    @extend .px-075r;
    @extend .py-075r;
  }

  .pt-0875r {
    padding-top: 0.875rem;
  }
  .pb-0875r {
    padding-bottom: 0.875rem;
  }
  .py-0875r {
    @extend .pt-0875r;
    @extend .pb-0875r;
  }
  .pl-0875r {
    padding-left: 0.875rem;
  }
  .pr-0875r {
    padding-right: 0.875rem;
  }
  .px-0875r {
    @extend .pl-0875r;
    @extend .pr-0875r;
  }
  .p-0875r {
    @extend .px-0875r;
    @extend .py-0875r;
  }

  .pt-2r {
    padding-top: 2rem;
  }

  .pb-2r {
    padding-bottom: 2rem;
  }

  .py-2r {
    @extend .pt-2r;
    @extend .pb-2r;
  }

  .ps-2r {
    padding-left: 2rem;
  }

  .pe-2r {
    padding-right: 2rem;
  }

  .px-2r {
    @extend .ps-2r;
    @extend .pe-2r;
  }

  .p-2r {
    @extend .px-2r;
    @extend .py-2r;
  }

  .pt-4r {
    padding-top: 4rem;
  }

  .pb-4r {
    padding-bottom: 4rem;
  }

  .py-4r {
    @extend .pt-4r;
    @extend .pb-4r;
  }

  .ps-4r {
    padding-left: 4rem;
  }

  .pe-4r {
    padding-right: 4rem;
  }

  .px-4r {
    @extend .ps-4r;
    @extend .pe-4r;
  }

  .p-4r {
    @extend .px-4r;
    @extend .py-4r;
  }

  .me-0125r {
    margin-right: 0.125rem;
  }

  .wp-60 {
    width: 60px;
  }

  .w-2r {
    width: 2rem;
  }

  .w-6r {
    width: 6rem;
  }

  .w-10r {
    width: 10rem;
  }

  .w-11\.875r {
    width: 11.875rem;
  }

  .hp-60 {
    height: 60px;
  }

  .h-2r {
    height: 2rem;
  }

  .h-5r {
    height: 5rem;
  }

  .h-12\.5r {
    height: 12.5rem;
  }

  .sp-60 {
    @extend .wp-60;
    @extend .hp-60;
  }

  .text-underline {
    text-decoration: underline;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .no-underline {
    text-decoration: none !important;
    &:hover {
      text-decoration: none !important;
      & * {
        text-decoration: none !important;
      }
    }
  }

  .no-after::after {
    display: none !important;
  }

  .no-resize {
    resize: none !important;
  }

  .small,
  .small * {
    @include font-size($small-font-size);
    font-weight: $font-weight-normal;
  }

  .font-smaller,
  .font-smaller * {
    font-size: calc(#{$font-size-base} - 1px) !important;
  }

  .font-larger {
    font-size: $font-size-lg;
  }

  input,
  select,
  textarea {
    &:disabled,
    &[readonly] {
      color: $gray;
    }
  }

  .mw-0r {
    min-width: 0rem;
  }

  .mw-11r {
    min-width: 11rem;
  }

  .mw-35r {
    min-width: 35rem;
  }

  .mw-1024p {
    min-width: 1024px;
  }

  .mh-3\.5r {
    min-height: 3.5rem;
  }

  .mh-75vh {
    min-height: 75vh;
  }

  .mh-90vh {
    min-height: 90vh;
  }

  @media (min-width: #{map-get($grid-breakpoints, xs)}) and (max-width: 1279px) {
    .mw-xs-xl-1280p {
      min-width: 1280px;
    }
  }

  @media (min-width: #{map-get($grid-breakpoints, xl)}) {
    .mw-xl-1280p {
      min-width: 1280px;
    }
  }

  .right-0 {
    right: 0;
  }

  .left-0 {
    left: 0;
  }

  .top-0 {
    top: 0;
  }

  .bottom-0 {
    bottom: 0;
  }

  .border-radius-0125r {
    border-radius: 0.125rem;
  }

  .z-index-below-navbar {
    z-index: $sticky-navbar-z-index - 1;
  }

  .form-check-input {
    background-size: auto;

    &:focus {
      box-shadow: unset;
    }

    &:hover {
      border-color: $primary-500;
    }
  }

  .modal {
    &-fit {
      max-width: fit-content;
    }

    .modal-footer {
      padding: 0;
      text-align: right;

      * {
        margin: 0;
      }
    }
  }

  .btn {
    &-negative {
      color: $gray;

      &:hover {
        color: $black
      }
    }

    &.no-focus:focus,
    &.no-focus {
      box-shadow: none;
    }
  }

  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up(#{$breakpoint}) {
      @each $d in $dimensions {
        .h-#{$breakpoint}-#{$d} {
          height: #{$d}#{'%'};
        }
        .vh-#{$breakpoint}-#{$d} {
          height: #{$d}vh;
        }
        .mvh-#{$breakpoint}-#{$d} {
          max-height: #{$d}vh;
        }
        .w-#{$breakpoint}-#{$d} {
          width: #{$d}#{'%'} !important;
        }

        .dvh-#{$d} {
          height: #{$d}vh; // fallback for browsers that do not support dvh
          height: #{$d}dvh;
        }
        .dvh-#{$breakpoint}-#{$d} {
          height: #{$d}vh; // fallback for browsers that do not support dvh
          height: #{$d}dvh;
        }
      }

      .fw-#{$breakpoint}-bold {
        font-weight: bold;
        font-family: "visuelt-bold-pro", sans-serif;
      }

      .fw-#{$breakpoint}-normal {
        font-weight: normal;
        font-family: "visuelt-regular-pro", sans-serif;
      }

      .w-#{$breakpoint}-auto {
        width: auto !important;
      }

      .border-#{$breakpoint}-bottom {
        border-bottom: 0.0625rem solid $gray-300;
      }

      .mh-#{$breakpoint}-auto {
        min-height: auto;
      }

      .text-#{$breakpoint}-wrap {
        white-space: normal !important;
      }
    }
  }

  @each $color, $value in $theme-colors-plus {
    .color-#{$color} {
      color: $value !important;
    }
    .hover-color-#{$color},
    .deep-hover.hover-color-#{$color} {
      &:hover {
        color: $value !important;
      }
    }

    .border-color-#{$color} {
      border-color: $value !important;
    }

    .hover-border-color-#{$color} {
      &:hover {
        border-color: $value !important;
      }
    }

    .hover-before-border-color-#{$color} {
      &:hover {
        *:before {
          border-color: $value !important;
        }
      }
    }

    .bg-#{$color} {
      background-color: $value !important;
    }
    .hover-bg-#{$color} {
      &:hover {
        background-color: $value !important;
      }
    }
    .deep-hover-color-#{$color}:hover {
      .deep-hover {
        color: $value !important;
      }
    }
    .deep-hover-bg-#{$color} {
      &:hover {
        .deep-hover {
          background-color: $value !important;
        }
      }
    }

    .show {
      .shown-color-#{$color} {
        color: $value !important;
      }
      .shown-bg-#{$color} {
        background-color: $value !important;
      }
    }
  }

  $displayOptions: ('block', 'flex', 'none', 'inline-block', 'inline-flex', 'inline-block');
  @each $d in $displayOptions {
    .deep-hover-d-#{$d}:hover {
      .deep-hover {
        display: string.unquote($d) !important;
      }
    }
    .hover-d-#{$d}:hover {
      display: string.unquote($d) !important;
    }

    // s - second hover trigger for ability to trigger hide on one element and show on another
    .s-deep-hover-d-#{$d}:hover {
      .s-deep-hover {
        display: string.unquote($d) !important;
      }
    }
    .s-hover-d-#{$d}:hover {
      display: string.unquote($d) !important;
    }
  }

  .show {
    .shown-underline {
      text-decoration: underline;
    }
  }

  .lh-1rem {
    line-height: 1rem !important;
  }

  .cursor-pointer {
    cursor: pointer;
    * {
      cursor: pointer;
    }
  }

  .cursor-not-allowed {
    cursor: not-allowed !important;
    * {
      cursor: not-allowed !important;
    }
  }

  .form-h-spacer {
    border-bottom: 1px solid $gray-200;
    width: 100%;
    margin-bottom: 1rem;
  }

  // icons
  svg.icon {
    height: 1rem;
    width: 1rem;
    display: inline;

    &.icon-large {
      height: 1.5rem;
      width: 1.5rem;
    }

    &.icon-xlarge {
      height: 2rem;
      width: 2rem;
    }
  }

  .icon {
    &::before {
      font-size: 1.5rem;
      line-height: 1.5rem;
      width: auto;
      margin: 0;
      display: inline-block;
    }

    &.icon-large::before {
      font-size: 2rem;
      line-height: 2rem;
    }

    &.icon-xlarge::before {
      font-size: 3rem;
      line-height: 3rem;
    }

    &.icon-3xlarge::before {
      font-size: 9.375rem;
      line-height: 9.375rem;
    }
  }

  [class^="ni-"], [class*=" ni-"] {
    font-family: inherit !important;
    line-height: 0;

    &:before {
      font-family: 'nSpace-icons' !important;
    }
  }

  .order-articles-list__body.scroll-v > draggable-container {
    border-top: 1px solid $light;
  }
  .order-articles-list__body.scroll-v > draggable-container {
    border-bottom: 1px solid $light;
  }

  $level-padding-gap: 1.45rem;
  .level {
    @for $i from 1 through 5 {
      &-#{$i} {
        .first-column {
          padding-left: $level-padding-gap * $i !important;
        }
      }
    }
  }

  draggable-item.selectedItem {
    & > div:not(.popover *),
    .bg-gray-200:not(.popover *) {
      background-color: $white !important;
    }
  }

  .edit-inline {
    padding-left: 0;
    padding-right: 0;
    background: transparent;

    &.ng-valid {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      background-color: $gray-200;
    }
  }

  .alert {
    padding: 0.5rem 3.625rem 0.5rem 1rem ;

    .close {
      text-shadow: none;
      opacity: 1;
      padding: 0.25rem;

      top: 0.5rem;
      right: 1rem;

      span {
        display: none;
      }

      &:hover {
        color: inherit;
      }
      &::after {
        font-family: nSpace-icons;
        content: "\e916";

        display: block;
        width: 1.5rem;
        height: 1.5rem;
        line-height: 1.5rem;
      }
    }
  }

  .app {
    &-card-container {
      display: flex;
      align-items: stretch;
    }

    &-card {
      margin: 1rem 0.75rem 0.75rem 0;
      padding: 1rem;
      background: $white;

      box-shadow: 0 0 0.125rem rgba($black, 0.12), 0.25rem 0.25rem 0 rgba($black, 0.08);
      border-radius: 0.125rem;
      display: flex;
      flex-direction: column;
      width: 100%;

      .title {
        margin-bottom: 0.5rem;
        h4 {
          margin-bottom: 0;
        }
      }

      .image {
        margin-top: auto;
        background-repeat: no-repeat;
        background-size: cover;
      }

      &:hover {
        margin-top: 0.5rem;
        margin-bottom: 1.25rem;
        box-shadow: 0.5rem 0.75rem 0.5rem rgba($black, 0.08);
      }
    }
  }

  .dropdown {
    &-menu {
      min-width: 13.75rem;
    }
  }

  .table-invisible {
    border-collapse: collapse;
    width: 100%;

    th,
    td {
      border: none;
    }

    th {
      font-weight: bold;
    }
  }

  .table-visible {
    border-collapse: collapse;
    width: 100%;

    th,
    td {
      border: none;
    }

    th {
      font-weight: bold;
      color: $black;
    }

    tr {
      border-bottom: $table-border-width solid $table-border-color!important;
    }
  }

  .object-fit-cover {
    object-fit: cover;
  }

  .bg-transition {
    -webkit-transition: background-color 300ms linear;
    -moz-transition: background-color 300ms linear;
    -o-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;
  }
}

.custom-radio {
  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-size: 0.625rem;
    }
  }
}

.nav.nav-pills {
  .nav-link {
    color: $gray;
    border-bottom: 3px solid $white;
    padding-bottom: calc(1rem - 3px);
    position: relative;

    &.active {
      color: $black;
      border-bottom: 3px solid $primary;
    }

    &:hover:not(&.active) {
      color: $black;
      border-bottom-color: transparent;
    }

    &:not(.full-bottom-border-width)::before,
    &:not(.full-bottom-border-width)::after {
      content: '';
      background-color: #fff;
      width: 1rem;
      height: 3px;
      bottom: -3px;
      position: absolute;
      z-index: 1;
    }

    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
}

.cdk-overlay-container {
  z-index: 1100;
}

.sidebar-modal {
  .modal-dialog {
    width: 23.4375rem;
    margin: 0 !important;
    transform: translate(100%, 0) !important;
  }

  .modal-content {
    height: 100%;
  }

  &.fade .modal-dialog {
    transform: translate(100%, 0) !important;
  }

  &.fade.show .modal-dialog {
    transform: translate(0, 0) !important;
  }
}

@mixin line-clamp($line-limit) {
  display: -webkit-box;
  -webkit-line-clamp: $line-limit;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.max-text-lines-2 {
  @include line-clamp(2);
}

.max-text-lines-3 {
  @include line-clamp(3);
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.text-white-space-normal {
  white-space: normal;
}

.skeleton-filler {
  background: $gray-300;
  background-image: linear-gradient(90deg, $gray-300, $gray-400, $gray-300);
  background-size: 200% 100%;
  animation: skeleton 1.5s infinite linear;
}

@keyframes skeleton {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

.shadow-card {
  box-shadow: 0 0.25rem 0.5rem rgba($black, 0.08) !important;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 0.5rem 1rem rgba($black, 0.24), 0 0 0.125rem rgba($black, 0.16) !important;
  }
}
